<template>
  <section class="banner">
    <img :src="require('@/assets/' + image_name)" alt="" />
    <div class="banner-generic">
      <h1>{{ header }}</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: "GenericBanner",
  props: {
    header: String,
    image_name: String,
  },
};
</script>

<style scoped lang="scss">
.banner {
  position: relative;
  height: 250px;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #292835;
    opacity: 0.9;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-generic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    h1 {
      font-size: 2em;
    }
  }
}
</style>