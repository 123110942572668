<template>
  <GenericBanner header="Contact Us" image_name="contact-us-banner.jpg" />

  <section class="contact">
    <div class="row contact-cols">
      <div class="col">
        <div class="container-light">
          <h2>Reach out to us!</h2>
          <form id="contact-form">
            <label for="name">Name</label>
            <input
              type="text"
              name="name"
              v-model="name"
              placeholder="Your name"
            />
            <label for="email">Email</label>
            <input
              type="email"
              name="email"
              v-model="email"
              placeholder="Your email"
            />
            <label for="message">Message</label>
            <textarea
              name="message"
              id="form-message"
              rows="6"
              v-model="message"
              placeholder="Enter your message..."
            ></textarea>
            <button class="button submit" id="submit-contact-form" :onclick="(e) => this.sendEmail(e)">
              <i class="far fa-paper-plane"></i> Send it
            </button>
          </form>
        </div>
      </div>

      <div class="col">
        <h3>Socials</h3>
        <p>
          Follow our social media and stay updated about future events and
          activities!
        </p>

        <div class="social-items">
          <a href="https://www.twitter.com/totemgamedev/" target="_blank" class="item"><i class="fab fa-2x fa-fw fa-twitter"></i>TotemGameDev</a>
          <a href="https://www.instagram.com/totemgamedev/" target="_blank" class="item"><i class="fab fa-2x fa-fw fa-instagram"></i>totemgamedev</a>
          <a href="https://www.linkedin.com/company/totem-game-dev/" target="_blank" class="item"><i class="fab fa-2x fa-fw fa-linkedin"></i>Totem Game Dev</a>
          <a href="mailto:totemgamedev@gmail.com" target="_blank" class="item"><i class="far fa-2x fa-fw fa-envelope"></i>totemgamedev@gmail.com</a>
        </div>

        <br />
        <br />

        <h3>Join the community</h3>
        <p>Would you like to become a member of Totem Game Dev? You can join our Discord server by clicking the button below!</p>
        <br />
        <DiscordButton />
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "emailjs-com";
import GenericBanner from "@/components/GenericBanner.vue";
import DiscordButton from "@/components/DiscordButton.vue";

export default {
  name: "Contact",
  inject: ["createNotification"],
  components: {
    GenericBanner,
    DiscordButton,
  },
  mounted() {
    emailjs.init("user_sqKAD0U31B71ZlKmy5n1q");
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    sendEmail(e) {
      // Prevent page reload
      e.preventDefault();

      const form = document.getElementById("contact-form");

      // Check if the pressed button was part of the contact form, and only then send the email
      if (e.target.id === 'submit-contact-form') {
        try {
          emailjs.sendForm(
            "service_tuegamedev",
            "template_tgd_contact",
            form,
            "user_sqKAD0U31B71ZlKmy5n1q",
            {
              name: this.name,
              email: this.email,
              message: this.message,
            }
          )
          
          this.createNotification({
            type: 'confirm',
            title: 'Success!',
            message: 'Message sent succesfully',
          })
        } catch (error) {
          this.createNotification({
            type: 'error',
            title: 'An error occurred',
            message: error
          })
        }

        // Reset form field
        this.name = "";
        this.email = "";
        this.message = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact {
  .contact-cols {
    padding-bottom: 0;
    justify-content: center;
    gap: 6rem;

    .col {
      max-width: 500px;
      width: 100%;
    }
    .col:nth-child(2) {
      max-width: 400px;

      p {
        word-spacing: 0.05em;
        letter-spacing: 0.01em;
        line-height: 1.5em;
      }
    }

    h3 {
      margin: 1rem 0 10px;
    }
  }

  .container-light {
    padding: 3rem 4rem;
    width: 100%;

    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    label {
      display: block;
      margin: 1rem 0 8px;
      font-size: 15px;
      color: #817f98;
    }

    form {
      input,
      textarea {
        width: 100%;
        padding: 0.7rem 1rem;
        font: 15px "Rubik", sans-serif;
        color: #fff;
        background-color: transparent;
        border: 2px solid #817f98;
        border-radius: 6px;
        transition: border-color 200ms ease;

        &::placeholder {
          color: #817f98;
        }

        & + * {
          margin-top: 1rem;
        }

        &:hover,
        &:focus {
          border-color: #fff;
          outline: none;
        }
      }

      textarea {
        resize: none;
        padding: 1rem;
      }
    }

    form .submit {
      width: 100%;
      padding: 1rem;
      font: 1rem "Rubik", sans-serif;
      color: #fff;
      background-color: #817f98;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 200ms ease;

      &:hover {
        background-color: #9d9bb6;
      }

      i {
        margin-right: 8px;
      }
    }
  }

  .social-items {
    margin-top: 8px;
  }
  .social-items a {
    display: block;
    width: auto;
    color: inherit;
    text-decoration: none;
    transition: color 200ms ease;
    padding: 10px 0;

    i {
      margin-right: 1rem;
    }
    & > * {
      vertical-align: middle;
    }

    &:hover {
      color: var(--color-purple-lightest);
    }
  }

  .container {
    padding: 15px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .contact .container-light {
    padding: 2rem;
  }
}
</style>